import React from "react";
import ProductTemplate from "./product";
import NewProductTemplate from "../../components/sections/product-more-info/Item";

// import { FiArrowRightCircle } from "react-icons/fi";
// import { Link } from "gatsby";
// import Title from "@components/common/Title";
// import Text from "@components/common/Text";

const Product = props => {
    const { pageContext } = props;
    const { item } = pageContext;

    const newProductsEnabled = process.env.GATSBY_NEW_PRODUCTS_ENABLED === "true";

    const Data = () => {
        return (
            <div className="space-y-4">
                <p>
                    <strong>Supera't amb la nostra LAST OF WEEK Mossos 2025!</strong>
                </p>

                <p>
                    🔹{" "}
                    <strong>
                        Tras l'èxit de l'any anterior, et portem el curs intensiu definitiu per
                        afrontar l'examen del 15 de febrer amb confiança!
                    </strong>
                </p>

                <ul>
                    <li>
                        ✔️ <strong>10 hores de temari en un sol dia</strong>
                    </li>
                    <li>
                        ✔️ <strong>Accés exclusiu al campus online amb l'àmbit D</strong>
                    </li>
                    <li>
                        ✔️ <strong>Fitxes resum per dominar cada tema</strong>
                    </li>
                </ul>

                <p>
                    <strong>📅 Escull la teva data:</strong>
                </p>
                <ul>
                    <li>Dissabte 1 de febrer</li>
                    <li>Diumenge 2 de febrer</li>
                    <li>Dissabte 8 de febrer</li>
                    <li>Diumenge 9 de febrer</li>
                </ul>

                <p>
                    <strong>⏰ Horari: de 9.00 a 14.00 i de 15.00 a 20.00 h</strong>
                </p>
                <p>
                    <strong>💶 Preu: només 109,99 €</strong>
                </p>

                <p>
                    👉{" "}
                    <strong>
                        No deixis escapar aquesta oportunitat d'arribar al màxim nivell! Apunta't
                        ara!
                    </strong>
                </p>

                <div className="overflow-hidden rounded-lg mt-10 border-4 border-gray-500 shadow-lg">
                    <iframe
                        title={item.title}
                        width="100%"
                        height="600px"
                        src="https://www.youtube.com/embed/vcqEY-ds1Ok"
                        frameBorder={"0"}
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen={true}
                    />
                </div>
            </div>
        );
    };

    return (
        <>
            {!newProductsEnabled ? (
                <ProductTemplate item={item}>
                    <Data />
                </ProductTemplate>
            ) : (
                <NewProductTemplate
                    item={item}
                    title="Prepara't per triomfar a l'examen de Mossos 2025 amb el curs intensiu que marca la diferència. Últim esforç, màxim resultat!"
                >
                    <Data />
                </NewProductTemplate>
            )}
        </>
    );
};
export default Product;
